/*
@contract page-header {
  background: BackgroundColor
  text: Color
  height: Height
  logo-color: Color
  border-bottom: BorderBottom
  position: Position
  z-index: ZIndex
  back {
    color: Color
    background-color: BackgroundColor
    background-color-active: BackgroundColor
  }
  links {
    background-color: BackgroundColor
    color: Color
    color-hover: Color
    color-active: Color
    font-weight: FontWeight
    text-decoration: TextDecoration
    text-decoration-hover: TextDecoration
  }
  favorite {
    color: Color
    color-hover: Color
    color-active: Color
    color-filled: Color
    color-filled-hover: Color
    color-filled-active: Color
  }
}
*/

.pageHeader {
  --logo-color: var(--page-header-logo-color);
  width: 100%;
  height: var(--page-header-height);
  background: var(--page-header-background);
  color: var(--page-header-text);
  border-bottom: var(--page-header-border-bottom);
  display: flex;
  align-items: center;
  position: var(--page-header-position);
  z-index: var(--z-index-5x);
}

.pageHeaderLeft {
  display: flex;
  flex: 1;
  align-items: center;
  min-width: 205px;
}

.pageHeaderBack {
  width: var(--page-header-back-width, 67px);
  color: var(--page-header-back-color);
  background: var(--page-header-back-background-color);
  height: var(--page-header-height);
  font-size: var(--font-size-xl);
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageHeaderBack:hover {
  background: var(--page-header-back-background-color);
}

.pageHeaderBack:active {
  background: var(--page-header-back-background-color-active);
}

.pageHeaderLogoLink {
  display: inline-flex;
}

.pageHeaderLogo {
  position: relative;
  flex: 1;
  height: var(--page-header-logo-height, 38px);
  width: var(--page-header-logo-width);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.pageHeaderLinks {
  list-style: none;
  display: inline-flex;
  font-size: var(--font-size-body);
  align-items: center;
  justify-content: center;
  padding: 0 var(--space-3x) 0 0;
}

.pageHeaderLinksHiddenOnMobile {
  display: none;
  @media (--screen-md) {
    display: flex;
  }
}

.pageHeaderLink {
  color: var(--page-header-links-color);
  font-weight: var(--page-header-links-font-weight);
  min-width: var(--space-12x);
  text-decoration: var(--page-header-links-text-decoration);
  padding: 0 var(--space-3x);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  height: calc(var(--page-header-height) - var(--space-2x));

  &button {
    padding: 0;
  }
}

.pageHeaderLink:hover {
  color: var(--page-header-links-color-hover);
  text-decoration: var(--page-header-links-text-decoration-hover);
}

.pageHeaderAuthLinksWrapper {
  display: inline-flex;
}

.pageHeaderPipe::before {
  display: inline-flex;
  content: '';
  width: 2px;
  height: 16.4px;
  background: var(--color-gray-200);
  align-self: center;
}

.pageHeaderMobileContent {
  flex-grow: 1;
  justify-content: flex-end;
  padding: 0 var(--space-3x);
  display: flex;

  @media (--screen-md) {
    display: none;
  }
}

.pageHeaderFixed {
  position: sticky;
  top: 0;
  z-index: var(--z-index-2x);
}

.pageHeaderSkipLink {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-200);
  z-index: var(--z-index-2x);
  padding: var(--space-4x);
  font-size: var(--font-size-md);
}

.pageHeaderFavoriteLink {
  color: var(--page-header-links-color);
  font-weight: var(--font-weight-semibold);
  height: calc(var(--page-header-height) - var(--space-2x));
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: var(--space-1x);
  padding: 0 var(--space-3x);
}

.pageHeaderCount {
  color: var(--page-header-links-color);
  display: block;
  padding-left: var(--space-1x);
}

.pageHeaderFavoriteLink:hover .pageHeaderCount {
  color: var(--page-header-favorite-color-hover);
  text-decoration: underline;
}

.pageHeaderFavoriteFilledIcon {
  color: var(--page-header-favorite-color-filled);
}

.hide {
  display: none;
}

.pageHeaderHiddenWithMobileContent {
  display: none;

  @media (--screen-md) {
    display: flex;
  }
}

.pageHeaderLoginLink {
  display: inline-flex;
}

.pageHeaderSignupLink {
  display: none;

  @media (--screen-md) {
    display: inline-flex;
  }
}

.pageHeaderSavedApts {
  display: flex;
}
